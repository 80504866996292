import { useEffect, useRef, useState } from 'react'
import { Tenant } from 'models/tenant'
import { ListingRef } from 'models/listing'

export const useResizeObserver = (tenant: Tenant, chargersNotAssigned: ListingRef[]) => {
  const [chargerIdsExpanded, setChargerIdsExpanded] = useState(false)
  const [isMultiLine, setIsMultiLine] = useState(false)
  const [heightOfClosedTags, setHeightOfClosedTags] = useState(0)
  const tagsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateContainerHeight = (expanded: boolean) => {
      if (!tagsRef.current) return

      const container = tagsRef.current
      const singleLineHeight = container.children[0]?.clientHeight || 0
      const isMultiLine = container.scrollHeight > (expanded ? heightOfClosedTags : container.clientHeight)

      setIsMultiLine(isMultiLine)
      setHeightOfClosedTags(container.clientHeight)

      // Set the height based on the expanded state
      container.style.maxHeight =
        expanded || !isMultiLine
          ? '1000px' // Expanded state
          : `${singleLineHeight + 10}px` // Collapsed state
    }

    updateContainerHeight(chargerIdsExpanded)
  }, [chargersNotAssigned])

  useEffect(() => {
    if (tagsRef.current) {
      const container = tagsRef.current
      const singleLineHeight = container.children[0]?.clientHeight || 0

      // Set the height based on expanded state
      if (chargerIdsExpanded) {
        container.style.maxHeight = '1000px' // Expanded state
      } else {
        container.style.maxHeight = `${singleLineHeight + 10}px` // Collapsed state
      }
    }
  }, [chargerIdsExpanded])

  return {
    chargerIdsExpanded,
    tagsRef,
    isMultiLine,
    setChargerIdsExpanded,
  }
}
