import { List } from 'antd'

import TotalCard from 'atom/cards/TotalCard'
import { ListCardWrapper } from 'atom/list'

import { Financial } from 'models/transaction'
import { formatPrice } from 'helpers/number-format'
import { useFinancialTranslation } from 'hooks/translation/useFinancialTranslation'
import { useMemo } from 'react'

interface FinancialProps {
  financial: Financial
}

const TransactionPanelTotal: React.FC<FinancialProps> = ({ financial: { tax, subtotal, total, currency, localTax } }) => {
  const { taxText, subTotalText, totalText, localTaxText } = useFinancialTranslation()

  const formattedPrices = useMemo(() => ({
    subtotal: formatPrice(subtotal / 100, currency),
    tax: formatPrice(tax / 100, currency),
    total: formatPrice(total / 100, currency),
    localTax: localTax !== null && localTax !== undefined ? formatPrice(localTax / 100, currency) : null
  }), [subtotal, tax, total, localTax, currency])

  const renderListItem = (title: string, amount: string | null, customClass = "heading-06-regular-td") => (
    amount !== null ? (
      <List.Item actions={[<span className={customClass}>{amount}</span>]}>
        <List.Item.Meta title={title} />
      </List.Item>
    ) : null
  )

  return (
    <ListCardWrapper>
      <TotalCard>
        {renderListItem(subTotalText, formattedPrices.subtotal)}
        {renderListItem(taxText, formattedPrices.tax)}
        {localTax !== null && localTax !== undefined && localTax > 0 && renderListItem(localTaxText, formattedPrices.localTax)}
        {renderListItem(totalText, formattedPrices.total, "paragraph-03-bold")}
      </TotalCard>
    </ListCardWrapper>
  )
}

export default TransactionPanelTotal
