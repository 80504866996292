import { stringify } from 'query-string'
import configProvider from '../config'
import { ListingRef } from '../models/listing'

// i.e. this returns a base url i.e: https://admin.swtchenergy.com
export function buildBaseUrl(): string {
  const { protocol, host } = window.location

  return `${protocol}//${host}`
}

export function buildAppUrl(path: string): string {
  return `${buildBaseUrl()}${path}`
}

export function buildApiFleetBaseUrl(): string {
  const { protocol } = window.location
  let hostname = configProvider.config.apiDomain
  return `${configProvider.config.isHttps ? 'https:' : protocol}//${hostname}/api/fleet/v1`
}

export function buildApiBaseUrl(): string {
  const { protocol } = window.location
  let hostname = configProvider.config.apiDomain
  return `${configProvider.config.isHttps ? 'https:' : protocol}//${hostname}/api/v2`
}

export function buildApiManageUrl(): string {
  return `${buildApiBaseUrl()}/manage`
}

export function buildApiBaseCihUrl(): string {
  const { protocol } = window.location
  const hostname = configProvider.config.cihApiDomain
  return `${protocol}//${hostname}/cih/v1`
}

export function buildApiManageUrl2(): string {
  const apiDomain = configProvider.config.dashboardApiDomain
  return `https://${apiDomain}`
}

export function buildRailsListingPageUrl(): string {
  const { protocol } = window.location
  const hostname = configProvider.config.apiDomain
  return `${configProvider.config.isHttps ? 'https:' : protocol}//${hostname}/admin/listings`
}

export function buildRailsListingUrl(listing: ListingRef): string {
  const { protocol } = window.location
  let hostname = configProvider.config.apiDomain
  return `${configProvider.config.isHttps ? 'https:' : protocol}//${hostname}/en/listings/${listing.id}-${
    listing.title
  }`
}

export function buildRailsListingUrlWithId(listingId: number, listingTitle: string): string {
  const { protocol } = window.location
  let hostname = configProvider.config.apiDomain
  return `${configProvider.config.isHttps ? 'https:' : protocol}//${hostname}/en/listings/${listingId}-${listingTitle}`
}

export function addQueryString(
  path: string,
  queryParams: {
    [key: string]: string | boolean | string[] | number
  },
): string {
  const querystring = stringify(queryParams)
  return path + '?' + querystring
}

export function addChargerQueryString(
  path: string,
  queryParams: {
    [key: string]: string | boolean
  },
): string {
  const querystring = stringify(queryParams)
  return path + '&' + querystring
}

export function buildPublicFontUrl(fontFamily: string): string {
  return `'${configProvider.config.publicAssetsDomain}/fonts/${fontFamily}'`
}

export function buildMetabaseUrl(): string {
  return configProvider.config.metabaseApiDomain
}
