import { RemoteRestartChargerController } from './remote-restart-charger-controller'
import { RemoteClearCacheController } from './remote-clear-cache-controller'
import { RemoteTriggerMessageController } from './remote-trigger-message-controller'
import { RemoteFirmwareUpdateController } from './remote-firmware-update-controller'
import { Charger } from '../../models/charger'
import { Tabs } from 'antd'
import { RemoteUpdateConfiguration } from './remote-update-configuration'
import { RemoteGetConfigurationController } from './remote-get-configuration'
import { ChangeAvailabilityController } from './change-avalibility-controller'
import { Connector } from '../../models/connector'
import { renderFormatMessage } from '../../helpers/intl'
import { useAppState } from '../../state'

const { TabPane } = Tabs

interface props {
  charger: Charger
  connector: Connector
}

export const ChargerTabRemote: React.FC<props> = ({ charger, connector }) => {
  const { IsDesktop } = useAppState()
  const isDesktop = IsDesktop()

  return (
    <Tabs tabPosition={isDesktop ? 'left' : 'top'}>
      <TabPane
        tab={renderFormatMessage(
          'dashboard.chargerPage.remoteControlTab.restartChargeBox.heading',
          'Restart charge box',
        )}
        key="restart_charge_box"
      >
        <RemoteRestartChargerController chargers={[charger]} />
      </TabPane>
      <TabPane
        tab={renderFormatMessage('dashboard.chargerPage.remoteControlTab.clearCache.heading', 'Clear Cache')}
        key="clear_cache"
      >
        <RemoteClearCacheController chargers={[charger]} />
      </TabPane>
      <TabPane
        tab={renderFormatMessage('dashboard.chargerPage.remoteControlTab.triggerMessage.heading', 'Trigger Message')}
        key="trigger_message"
      >
        <RemoteTriggerMessageController charger={charger} connector={connector} />
      </TabPane>
      <TabPane
        tab={renderFormatMessage('dashboard.chargerPage.remoteControlTab.updateFirmware.heading', 'Update Firmware')}
        key="update_firmware"
      >
        <RemoteFirmwareUpdateController chargers={[charger]} />
      </TabPane>
      <TabPane
        tab={renderFormatMessage(
          'dashboard.chargerPage.remoteControlTab.changeConfiguration.heading',
          'Change Configuration',
        )}
        key="change_configuration"
      >
        <RemoteUpdateConfiguration chargers={[charger]} />
      </TabPane>
      <TabPane
        tab={renderFormatMessage(
          'dashboard.chargerPage.remoteControlTab.getConfiguration.heading',
          'Get Configuration',
        )}
        key="get_configuration"
      >
        <RemoteGetConfigurationController charger={charger} />
      </TabPane>
      <TabPane
        tab={renderFormatMessage(
          'dashboard.chargerPage.remoteControlTab.changeAvailability.heading',
          'Change Availability',
        )}
        key="change_availability"
      >
        <ChangeAvailabilityController charger={charger} connector={connector} />
      </TabPane>
    </Tabs>
  )
}
