import { useFormatMessage } from '../../helpers/intl'

export const useFinancialTranslation = () => {
  const taxText = useFormatMessage('dashboard.transactionPage.tax', 'Tax')
  const subTotalText = useFormatMessage('dashboard.transactionPage.subtotal', 'Sub-Total')
  const totalText = useFormatMessage('dashboard.transactionsPage.statsView.total', 'Total')
  const localTaxText = useFormatMessage('dashboard.transactionsPage.local-tax', 'Local Tax')

  return {
    localTaxText,
    taxText,
    subTotalText,
    totalText,
  }
}
