import { Empty, PageHeader, Table } from 'antd'
import { Tenant } from '../../models/tenant'
import { Box } from '../../atom/box'
import { ButtonModal } from 'atom/button'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { plusSVG } from 'assets/svg/plus'
import { CreateAndEditAccessPlanModal } from './modal/create-and-edit-access-plan-modal'
import { AccessPlanColumns } from './table/access-plan-columns'
import { AccessPlanTabWrapper } from 'atom/chargers'
import { AccessPlanDriversModal } from './modal/access-plan/access-drivers-modal'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { useAccessPlanView } from 'hooks/useAccessPlanView'
import { ChargersNotLinkedHeader } from './tenant-tab-access-header'
import { useResizeObserver } from 'hooks/useResizeObserver'
import { ArchiveAccessPlanModal } from './modal/access-plan/archive-access-plan-modal'

interface tenantTabDetailProps {
  tenant: Tenant
}

export const TenantTabAccessPlan: React.FC<tenantTabDetailProps> = ({ tenant }) => {
  const { accessPlanText } = useTenantTranslation()
  const { accessPlanFlexText, addAccessPlanStartText } = useAccessPlanTranslation()
  const {
    expandableRowKeys,
    expanded,
    accessPlans,
    pagination,
    openCreateAccessPlanModal,
    selectedAccessPlanDrivers,
    selectedAccessPlan,
    setSelectedAccessPlanDrivers,
    setOpenCreateAccessPlanModal,
    handleRowExpand,
    handleCreateAccessPlanModal,
    onPaginationChange,
    getAccessViewData,
    openEditModal,
    setSelectedAccessPlan,
    getChargers,
    openArchive,
    handleArchive,
    chargersNotAssigned,
    selectedArchiveAccessPlan,
  } = useAccessPlanView(tenant)

  const { chargerIdsExpanded, tagsRef, isMultiLine, setChargerIdsExpanded } = useResizeObserver(
    tenant,
    chargersNotAssigned,
  )

  return (
    <AccessPlanTabWrapper>
      <PageHeader
        title={<div className="heading-06-regular">{accessPlanFlexText}</div>}
        extra={
          <ButtonModal
            size="large"
            click={handleCreateAccessPlanModal}
            icon={plusSVG}
            text={accessPlanText}
            type="primary"
          />
        }
      />
      <ChargersNotLinkedHeader
        chargersNotAssigned={chargersNotAssigned}
        chargerIdsExpanded={chargerIdsExpanded}
        tagsRef={tagsRef}
        isMultiLine={isMultiLine}
        setChargerIdsExpanded={setChargerIdsExpanded}
      />
      <ArchiveAccessPlanModal open={openArchive} accessPlan={selectedArchiveAccessPlan} onCancel={handleArchive} />
      <Box padding>
        <Table
          columns={AccessPlanColumns(
            expandableRowKeys,
            expanded,
            handleRowExpand,
            setSelectedAccessPlanDrivers,
            openEditModal,
            handleArchive,
          )}
          dataSource={accessPlans}
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={addAccessPlanStartText} />,
          }}
          pagination={{
            position: ['bottomCenter'],
            total: pagination?.totalEntries,
            current: pagination?.currentPage,
            pageSize: pagination?.perPage,
            showSizeChanger: false,
            onChange: onPaginationChange,
          }}
        />
      </Box>
      <CreateAndEditAccessPlanModal
        visible={openCreateAccessPlanModal}
        tenant={tenant}
        accessPlans={accessPlans}
        selectedAccessPlan={selectedAccessPlan}
        onCancel={() => {
          setSelectedAccessPlan(undefined)
          setOpenCreateAccessPlanModal(false)
        }}
        setVisible={setOpenCreateAccessPlanModal}
        onOk={() => {
          setSelectedAccessPlan(undefined)
          getAccessViewData()
          getChargers()
        }}
        setSelectedAccessPlan={setSelectedAccessPlan}
      />
      <AccessPlanDriversModal
        visible={!!selectedAccessPlanDrivers}
        selectedAccessPlanDrivers={selectedAccessPlanDrivers}
        onCancel={() => setSelectedAccessPlanDrivers(undefined)}
      />
    </AccessPlanTabWrapper>
  )
}
