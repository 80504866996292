import React, { useState } from 'react'
import { Space, Typography } from 'antd'

import { describeSchedule } from 'helpers/schedule'
import { renderFormatMessage } from 'helpers/intl'
import { capitalizeFirstLetter } from 'helpers/users'

import { AccessPlanChargersPage, AccessPlanSchedule } from 'models/access-plan'

import ChargerAccessModal from './ChargerAccessModal'
import { AlignedContentLeft } from 'components/wrapper'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'

interface ChargerAccessProps {
  listingTitle: string
  accessPlans: AccessPlanChargersPage[]
}
const ChargerAccess: React.FC<ChargerAccessProps> = ({ listingTitle, accessPlans }) => {
  const [openModal, setOpenModal] = useState(false)
  const { restrictedText, unrestrictedText } = useAccessPlanTranslation()

  const handleCloseModal = () => setOpenModal(false)
  const handleOpenModal = () => setOpenModal(true)

  const renderModal = (
    <>
      <Typography.Text underline className="pointer" onClick={handleOpenModal}>
        {renderFormatMessage('dashboard.text.accessPlanDetails', 'Access Plan Details')}
      </Typography.Text>
      <ChargerAccessModal
        open={openModal}
        listingTitle={listingTitle}
        accessPlans={accessPlans}
        onClose={handleCloseModal}
      />
    </>
  )

  const renderEmailAssociatedToAccessPlan = (emailDomains: string[]) => {
    if (emailDomains.length === 0) {
      return null
    }

    return (
      <Space wrap size={[4, 4]} className="paragraph-04-light opacity-06">
        {emailDomains.map((email) => (
          <React.Fragment key={email}>{email}</React.Fragment>
        ))}
      </Space>
    )
  }

  const hasMultipleDays = (schedules: AccessPlanSchedule) => {
    return Object.values(schedules).some((schedule) => schedule.length > 1)
  }

  const renderAccessCode = (accessCode: string) => {
    if (!accessCode) {
      return null
    }

    return (
      <span className="paragraph-04-light opacity-06">
        {renderFormatMessage('dashboard.tenantPage.accesscode.heading', 'Access Code')}: {accessCode}
      </span>
    )
  }

  const renderSingleAccessPlanInfo = (
    accessType: string,
    accessCode: string,
    schedules: AccessPlanSchedule,
    emailDomains: string[],
  ) => {
    const renderSchedules = describeSchedule(schedules)
    return (
      <AlignedContentLeft>
        {accessType === 'unrestricted' ? unrestrictedText : restrictedText} {renderSchedules === '24/7' ? '-' : null}{' '}
        {renderSchedules}
        {renderAccessCode(accessCode)}
        {renderEmailAssociatedToAccessPlan(emailDomains)}
      </AlignedContentLeft>
    )
  }

  const renderViewAccessPlanDetailsWithName = (accessType: string) => (
    <div className="flex-and-gap-below">
      {capitalizeFirstLetter(accessType)} {renderModal}
    </div>
  )

  const renderSingleAccessPlan = (plan: AccessPlanChargersPage) => {
    const { access_type, schedules, access_code, email_domains } = plan

    return hasMultipleDays(schedules)
      ? renderViewAccessPlanDetailsWithName(access_type)
      : renderSingleAccessPlanInfo(access_type, access_code, schedules, email_domains)
  }

  const onlyOneScheduleInWeek = (schedules: AccessPlanSchedule) => {
    const scheduleValues = Object.values(schedules)
    return scheduleValues.filter((value) => value.length === 1).length === 1
  }

  const areSchedulesEqual = (schedules: AccessPlanSchedule) => {
    if (onlyOneScheduleInWeek(schedules)) return true

    const scheduleValues = Object.values(schedules)
    let customDayEqual = true
    if (scheduleValues.length === 7) {
      const weekday = scheduleValues.slice(0, 5)
      const weekend = scheduleValues.slice(5, 7)
      const oneOrNoWeekday = weekday.filter((value) => value.length).length <= 1
      const oneOrNoWeekend = weekend.filter((value) => value.length).length <= 1

      const weekdayEqual =
        oneOrNoWeekday || weekday.every((value) => JSON.stringify(value) === JSON.stringify(weekday[0]))
      const weekendEqual =
        oneOrNoWeekend || weekend.every((value) => JSON.stringify(value) === JSON.stringify(weekend[0]))

      customDayEqual = weekdayEqual && weekendEqual
    }
    return (
      customDayEqual &&
      scheduleValues.every(
        (value) =>
          !value.length ||
          JSON.stringify(value) === JSON.stringify(scheduleValues[0]) ||
          JSON.stringify(value) === JSON.stringify(scheduleValues[5]),
      )
    )
  }

  // three possible scenarios below:
  // 1. no access plan found
  if (accessPlans.length === 0) {
    return renderFormatMessage('dashboard.text.noAccessPlan', 'No Access Plan')
  }

  // 2. single access plan found and has one time slot
  if (accessPlans.length === 1 && areSchedulesEqual(accessPlans[0].schedules)) {
    return renderSingleAccessPlan(accessPlans[0])
  }

  // 3. multiple access plans found or has multiple time slot
  return renderModal
}

export default ChargerAccess
