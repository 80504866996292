import { useFormatMessage } from '../../helpers/intl'

export const useChargerRemoteRequestTranslation = () => {
  const changeAvailabilityText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.p1',
    'Click below to change the connector’s availability to:',
  )

  const noteText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.note',
    'Note: connector’s availability status can only be changed to Available when the current OCPP status is Unavailable. And changed to Unavailable if the current status is Available.',
  )

  const changeAvailabilityRequestResponseSucessText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.request.response.success',
    'Request has been sent to update availability.',
  )

  const changeAvailabilityRequestResponseFailedText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.request.response.failed',
    'Failed to update availability.',
  )

  const changeAvailabilityRequestSpinText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.request.spin',
    'Changing availability status...',
  )

  const inoperativeText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.request.value.inoperative',
    'Inoperative',
  )
  const operativeText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.request.value.operative',
    'Operative',
  )
  const availableText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.request.button.available',
    'Available',
  )
  const unavailableText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.request.button.unavailable',
    'Unavailable',
  )
  const NAText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.changeAvailability.request.button.na',
    'N/A',
  )
  
  return {
    changeAvailabilityText,
    noteText,
    changeAvailabilityRequestResponseSucessText,
    changeAvailabilityRequestResponseFailedText,
    changeAvailabilityRequestSpinText,
    availableText,
    unavailableText,
    inoperativeText,
    operativeText,
    NAText
  }
}
