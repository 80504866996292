import { Alert, Button, Col, Row, Spin } from 'antd'
import { useState } from 'react'
import { Charger } from '../../models/charger'
import { Connector } from '../../models/connector'
import { RemoteChargerChangeAvailabilityParams } from '../../models/charger-remote'
import { ChargerChangeAvailability } from '../../services/data-provider/charger-remote'
import { useAppState } from 'state'
import { useChargerRemoteRequestTranslation } from '../../hooks/translation/useChargerRemoteRequestTranslation'

interface Props {
  charger: Charger
  connector: Connector
}

export const ChangeAvailabilityController: React.FC<Props> = ({ charger, connector }) => {
  const { isMockUpEnabled } = useAppState()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const {
    changeAvailabilityText,
    noteText,
    changeAvailabilityRequestSpinText,
    availableText,
    unavailableText,
    inoperativeText,
    operativeText,
    NAText,
  } = useChargerRemoteRequestTranslation()

  const isAvailable = connector.state === availableText
  const isUnavailable = connector.state === unavailableText
  const buttonDisabled = !isAvailable && !isUnavailable
  const newStatus = isAvailable ? inoperativeText : isUnavailable ? operativeText : ''
  const buttonText = isAvailable ? unavailableText : isUnavailable ? availableText : NAText

  const chargerObj: RemoteChargerChangeAvailabilityParams = {
    charger_id: charger.id,
    connector_id: connector.connectorId,
    status: newStatus,
  }

  const handleChangeAvailability = () => {
    setLoading(true)
    setMessage(null)
    setError(null)

    ChargerChangeAvailability(isMockUpEnabled, chargerObj.charger_id, chargerObj.connector_id, chargerObj.status)
      .then((resp) => {
        if (resp.success) {
          setMessage(resp.message)
        } else {
          setError(resp.message)
        }
      })
      .catch((resp) => setError(resp.message))
      .finally(() => setLoading(false))
  }

  return (
    <Row>
      <Col span={24}>
        <p>{changeAvailabilityText}</p>
        <Button onClick={handleChangeAvailability} loading={loading} disabled={buttonDisabled}>
          {buttonText}
        </Button>
      </Col>
      <Col span={24}>
        <p>{noteText}</p>
      </Col>
      <Col span={24} style={{ paddingTop: '10px' }}>
        {loading && (
          <Alert
            message={
              <div>
                <Spin style={{ marginRight: '10px' }} />
                {changeAvailabilityRequestSpinText}
              </div>
            }
          />
        )}
        {!loading && message && <Alert message={message} type="info" />}
        {!loading && error && <Alert message={error} type="error" />}
      </Col>
    </Row>
  )
}
