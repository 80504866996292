import { Button, Modal } from 'antd'
import { useState } from 'react'
import { SwtchError } from '../../../../models/error'
import { AccessPlan } from '../../../../models/access-plan'
import { ArchiveAccessPlan } from '../../../../services/data-provider/access-plan'
import { AlertError } from '../../../error'
import { FooterLayout } from '../../../../atom/user-edit'
import { HeadingWrapper, TextBodyWrapper } from '../../../../atom/plans'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { circleErrorSVG } from 'assets/svg/error'
import { useAppState } from 'state'

interface props {
  accessPlan?: AccessPlan
  open: boolean
  onCancel: () => void
}

export const ArchiveAccessPlanModal: React.FC<props> = ({ accessPlan, open, onCancel }) => {
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const { isMockUpEnabled } = useAppState()
  const {
    accessPlanArchiveModalButtonText,
    accessPlanArchiveModalHeadingText,
    accessPlanArchiveModalActionText,
    accessPlanArchiveModalBodyULText,
    accessPlanArchiveModalCancelButtonText,
  } = useAccessPlanTranslation()

  const onOk = () => {
    setError(undefined)
    setLoading(true)
    if (accessPlan) {
      ArchiveAccessPlan(isMockUpEnabled, accessPlan.id, accessPlan.tenantId)
        .then(() => {
          onCancel()
        })
        .catch((error: SwtchError) => setError(error))
        .finally(() => setLoading(false))
    }
  }
  return open ? (
    <Modal
      title={<HeadingWrapper>{accessPlanArchiveModalButtonText}</HeadingWrapper>}
      closable={true}
      visible={open}
      onCancel={onCancel}
      onOk={onOk}
      afterClose={onCancel}
      footer={
        <FooterLayout>
          <Button key="back" loading={loading} onClick={onCancel}>
            {accessPlanArchiveModalCancelButtonText}
          </Button>
          <Button className="red" key="submit" type="primary" danger loading={loading} onClick={onOk}>
            {accessPlanArchiveModalButtonText}
          </Button>
        </FooterLayout>
      }
    >
      <AlertError error={error} />
      {circleErrorSVG}
      <HeadingWrapper>{accessPlanArchiveModalHeadingText}</HeadingWrapper>
      <TextBodyWrapper>
        {accessPlanArchiveModalActionText}
        <ul>
          <li>{accessPlanArchiveModalBodyULText}</li>
        </ul>
      </TextBodyWrapper>
    </Modal>
  ) : null
}
